<template>
    <vx-card title="Simple Stock Transfer Order DO">
        <div class="vx-input-group flex">
            <vs-tabs>
                <vs-tab label="Open">
                    <tab-open></tab-open>
                </vs-tab>
                <vs-tab label="Delivered">
                    <tab-delivered></tab-delivered>
                </vs-tab>
                <vs-tab label="Received">
                    <tab-received></tab-received>
                </vs-tab>
                <vs-tab label="Complete">
                    <tab-complete></tab-complete>
                </vs-tab>
            </vs-tabs>
        </div>
    </vx-card>
</template>
<script>
import TabOpen from "./TabOpen.vue";
import TabDelivered from "./TabDelivered.vue";
import TabReceived from "./TabReceived.vue";
import TabComplete from "./TabComplete.vue";
export default {
    components: {
        TabOpen,
        TabDelivered,
        TabReceived,
        TabComplete,
    },
    data() {
        return {
            activeTab: "Open",
        }
    },
    methods: {

    }
}
</script>