<template>
    <div>
        <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div
                        class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>
            <template slot="thead">
                <vs-th></vs-th>
                <vs-th sort-key="">STO Doc. Num.</vs-th>
                <vs-th sort-key="">DO Number</vs-th>
                <vs-th sort-key="">Delivery Date</vs-th>
                <vs-th sort-key="">Source Warehouse</vs-th>
                <vs-th sort-key="">Dest. Warehouse</vs-th>
                <vs-th sort-key=""># of SKU</vs-th>
                <vs-th sort-key="">Total QTY (UOM)</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div class="vx-input-group flex">
                            <vs-button size="small" color="#7d33ff" icon-pack="feather" icon="icon-printer" title="Print DO"
                                v-if="status === 'Open'" style="margin-right: 5px" :disabled="tr.do_number === null" @click="handlePrint(tr.id)" />
                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-edit" title="Edit DO"
                                v-if="status === 'Open'" style="margin-right: 5px" @click="handleEdit(tr.id)" />
                            <vs-button size="small" color="success" icon-pack="feather" icon="icon-check" title="Confirm"
                                v-if="status === 'Open'" @click="handleConfirm(tr.id)" />
                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-eye"
                                v-if="status !== 'Open'" title="Detail" @click="handleDetail(tr.id)" />
                        </div>
                    </vs-td>
                    <vs-td>{{ tr.code }}</vs-td>
                    <vs-td>{{ tr.do_number }}</vs-td>
                    <vs-td>{{ tr.date }}</vs-td>
                    <vs-td>{{ tr.src_warehouse_code }} {{ tr.src_warehouse_name }}</vs-td>
                    <vs-td>{{ tr.dest_warehouse_code }} {{ tr.dest_warehouse_name }}</vs-td>
                    <vs-td>{{ tr.total_sku }}</vs-td>
                    <vs-td>{{ tr.quantity_uom }}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>

<script>
import moment from "moment";
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        sourceWarehouseId: {
            type: Number,
        },
        transactionDate: {
            type: Date,
            default: null,
        },
        status: {
            type: String,
            default: "Open",
        },
    },
    data() {
        return {
            deleteId: null,
            table: this.tableDefaultState(),
            checkedAll: false,
            checked: [],
            selectedRouteAssignmentToAssign: [],
        };
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        handleClose() {
            this.getData();
            this.$emit("close");
        },
        handleCreate() {
            this.customerId = null;
            this.detail = true;
        },
        handlePrint(id) {
            const routeData = this.$router.resolve({
                name: "outbound.printdata",
                params: { id: id },
            });
            window.open(routeData.href, "_blank");
        },
        getData() {
            this.$vs.loading();
            this.$http
                .get("/api/wms/v1/outbound/simple-sto-do", {
                    params: {
                        length: this.table.length,
                        page: this.table.page,
                        search: this.table.search,
                        order: this.table.order,
                        sort: this.table.sort,
                        source_warehouse_id: this.sourceWarehouseId,
                        status: this.status,
                        transaction_date: (this.transactionDate) ? moment(this.transactionDate).format("YYYY-MM-DD") : null,
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.checkedAll = false;
                        this.checked = [];
                        this.selectedRouteAssignmentToAssign = [];

                        this.table.total = resp.data.total_record;
                        this.table.totalPage = resp.data.total_page;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.total_record_per_page;
                        this.table.data = resp.data.records;
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleDelete(id) {
            this.deleteId = id;
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Please confirm to delete this data",
                accept: this.acceptDelete,
            });
        },
        acceptDelete() {
            this.$vs.loading();
            this.$http
                .delete(this.baseUrl + "/" + this.deleteId + "/delete")
                .then((resp) => {
                    this.$vs.loading.close();
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "The data was successfully deleted",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    }
                    this.getData();
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        handleEdit(id) {
            this.$router.push({
                name: this.$store.state.outbound.simpleStoDo.baseRouterName + ".edit",
                params: { id: id },
            });
        },
        handleDetail(id) {
            this.$router.push({
                name: this.$store.state.outbound.simpleStoDo.baseRouterName + ".detail",
                params: { id: id },
            });
        },
        handleConfirm(id) {
            this.deleteId = id;
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to deliver this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http
                        .post("/api/wms/v1/outbound/simple-sto-do/confirm-action/" + this.deleteId)
                        .then((resp) => {
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "The data was successfully delivered",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }

                            this.$vs.loading.close();
                            this.getData();
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                },
            });
        },
        handleAssign(id) {
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to assign this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http
                        .put(this.baseUrl + "/" + id + "/status", {
                            status: "Assigned",
                        })
                        .then((resp) => {
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "The data was successfully assigned",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }

                            this.$vs.loading.close();
                            this.getData();
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                },
            });
        },
        handleAssignMultiple() {
            let routeAssignmentIDs = [];

            this.selectedRouteAssignmentToAssign.forEach((el) => {
                routeAssignmentIDs.push(el.id);
            });

            if (routeAssignmentIDs.length == 0) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Please select data to assign",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to assign this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http
                        .put(this.baseUrl + "/status/multiple", {
                            status: "Assigned",
                            route_assignment_ids: routeAssignmentIDs,
                        })
                        .then((resp) => {
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "The data was successfully assigned",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }

                            this.$vs.loading.close();
                            this.getData();
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                },
            });
        },
        handleDraft(id) {
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure change to draft this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http
                        .put(this.baseUrl + "/" + id + "/status", {
                            status: "Draft",
                        })
                        .then((resp) => {
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "The data was successfully change to draft",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }

                            this.$vs.loading.close();
                            this.getData();
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                },
            });
        },
        handleCancel(id) {
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to cancel this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http
                        .put(this.baseUrl + "/" + id + "/status", {
                            status: "Canceled",
                        })
                        .then((resp) => {
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "The data was successfully canceled",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }

                            this.$vs.loading.close();
                            this.getData();
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                },
            });
        },
        utcToLocal(val) {
            if (val) {
                return moment(val)
                    .add(-7, "h")
                    .utc()
                    .local()
                    .format("DD MMM YYYY HH:mm:ss");
            }
        },
        onchangeSelectedRouteAssignmentToAssign(e, index, row) {
            if (!this.checked[index]) {
                var fitered = this.selectedRouteAssignmentToAssign.filter((d) => {
                    return d.id == row.id;
                });

                if (fitered.length == 0) {
                    this.selectedRouteAssignmentToAssign.push(row);
                }
            } else {
                this.selectedRouteAssignmentToAssign.forEach((val, i) => {
                    if (val.id == row.id) {
                        this.selectedRouteAssignmentToAssign.splice(i, 1);
                    }
                });
                this.checkedAll = false;
            }
        },
    },
    mounted() { },
    watch: {
        // status() {
        //     this.getData();
        // },
        // territoryIDs() {
        //     this.getData();
        // },
        // personalIDs() {
        //     this.getData();
        // },
        // productTeamIDs() {
        //     this.getData();
        // },
        // detail() {
        //     this.getData();
        // },

        // checkedAll(val) {
        //     if (val) {
        //         this.selectedRouteAssignmentToAssign = [];
        //         this.table.data.forEach((el, index) => {
        //             this.checked[index] = true;
        //             this.selectedRouteAssignmentToAssign.push(el);
        //         });
        //     } else {
        //         if (
        //             this.selectedRouteAssignmentToAssign.length == this.checked.length
        //         ) {
        //             this.checked.forEach((el, index) => {
        //                 this.checked[index] = false;
        //             });
        //             this.selectedRouteAssignmentToAssign = [];
        //         }
        //     }
        // },
        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
};
</script>
  